<template>
  <b-card>
    <b-row align-h="center">
      <b-col cols="12">
        <app-timeline>
          <app-timeline-item variant="secondary"> 21/05/2021 </app-timeline-item>
          <app-timeline-item variant="primary" icon="PlusIcon">
            <div class="mb-1 mb-sm-0">
              <h6 class="d-inline-block">Tạo khoản ứng</h6>
              <small class="text-muted"> - 14:22</small>
            </div>
            <div class="d-flex flex-row align-items-center mb-50">
              <p class="mr-50">
                Tạo khoản ứng
                <a href="#">IL345</a>
              </p>
            </div>
            <b-media class="mb-50">
              <template #aside>
                <b-avatar :text="avatarText('Trần Thị Mai Anh')" size="30" />
              </template>
              <h6 class="mb-0">Trần Thị Mai Anh</h6>
              <span class="text-muted">Khách hàng</span>
            </b-media>
            <b-collapse id="report-list-with-icon">
              <b-table responsive :items="items" :fields="fields" class="mb-0">
                <template #cell(Phone)="data">
                  <span class="text-nowrap">
                    {{ data.value }}
                  </span>
                </template>

                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  {{ data.value }}
                </template>
              </b-table>
            </b-collapse>
          </app-timeline-item>
          <app-timeline-item variant="info" icon="Edit2Icon">
            <div class="mb-1 mb-sm-0">
              <h6 class="d-inline-block">Sửa lịch thu</h6>
              <small class="text-muted"> - 14:22</small>
            </div>
            <div class="d-flex flex-row align-items-center mb-50">
              <p class="mr-50">
                Sửa lịch thu
                <a href="#">IL345</a>
              </p>
              <b-button
                v-b-toggle.report-list-with-icon2
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                size="sm"
                variant="outline-primary"
              >
                Chi tiết
              </b-button>
            </div>
            <b-media class="mb-50">
              <template #aside>
                <b-avatar :src="require('@/assets/images/avatars/12-small.png')" size="30" />
              </template>
              <h6 class="mb-0">Nguyễn Thị Văn Tèo</h6>
              <span class="text-muted">Nhân viên sell</span>
            </b-media>
            <b-collapse id="report-list-with-icon2">
              <b-table responsive :items="items" :fields="fields" class="mb-0">
                <template #cell(Phone)="data">
                  <span class="text-nowrap">
                    {{ data.value }}
                  </span>
                </template>

                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  {{ data.value }}
                </template>
              </b-table>
            </b-collapse>
          </app-timeline-item>

          <app-timeline-item variant="secondary"> 21/05/2021 </app-timeline-item>
          <app-timeline-item variant="info" icon="Edit2Icon">
            <div class="mb-1 mb-sm-0">
              <h6 class="d-inline-block">Sửa nhân viên</h6>
              <small class="text-muted"> - 14:22</small>
            </div>
            <div class="d-flex flex-row align-items-center mb-50">
              <p class="mr-50">
                Sửa nhân viên
                <a href="#">IL8456</a>
              </p>
              <b-button
                v-b-toggle.report-list-with-icon6
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                size="sm"
                variant="outline-primary"
              >
                Chi tiết
              </b-button>
            </div>
            <b-media class="mb-50">
              <template #aside>
                <b-avatar :text="avatarText('Nguyễn Văn Thành')" size="30" />
              </template>
              <h6 class="mb-0">Nguyễn Văn Thành</h6>
              <span class="text-muted">Quản trị viên</span>
            </b-media>
            <b-collapse id="report-list-with-icon6">
              <b-table responsive :items="items" :fields="fields" class="mb-0">
                <template #cell(Phone)="data">
                  <span class="text-nowrap">
                    {{ data.value }}
                  </span>
                </template>

                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  {{ data.value }}
                </template>
              </b-table>
            </b-collapse>
          </app-timeline-item>

          <app-timeline-item variant="secondary"> 21/05/2021 </app-timeline-item>
          <app-timeline-item variant="info" icon="Edit2Icon">
            <div class="mb-1 mb-sm-0">
              <h6 class="d-inline-block">Sửa lịch thu</h6>
              <small class="text-muted"> - 14:22</small>
            </div>
            <div class="d-flex flex-row align-items-center mb-50">
              <p class="mr-50">
                Sửa lịch thu
                <a href="#">IL345</a>
              </p>
              <b-button
                v-b-toggle.report-list-with-icon3
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                size="sm"
                variant="outline-primary"
              >
                Chi tiết
              </b-button>
            </div>
            <b-media class="mb-50">
              <template #aside>
                <b-avatar :text="avatarText('Nguyễn Thị Văn Tèo')" size="30" />
              </template>
              <h6 class="mb-0">Nguyễn Thị Văn Tèo</h6>
              <span class="text-muted">Nhân viên sell</span>
            </b-media>
            <b-collapse id="report-list-with-icon3">
              <b-table responsive :items="items" :fields="fields" class="mb-0">
                <template #cell(Phone)="data">
                  <span class="text-nowrap">
                    {{ data.value }}
                  </span>
                </template>

                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  {{ data.value }}
                </template>
              </b-table>
            </b-collapse>
          </app-timeline-item>
          <app-timeline-item variant="info" icon="Edit2Icon">
            <div class="mb-1 mb-sm-0">
              <h6 class="d-inline-block">Sửa lịch thu</h6>
              <small class="text-muted"> - 14:22</small>
            </div>
            <div class="d-flex flex-row align-items-center mb-50">
              <p class="mr-50">
                Sửa lịch thu
                <a href="#">IL345</a>
              </p>
              <b-button
                v-b-toggle.report-list-with-icon4
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                size="sm"
                variant="outline-primary"
              >
                Chi tiết
              </b-button>
            </div>
            <b-media class="mb-50">
              <template #aside>
                <b-avatar :src="require('@/assets/images/avatars/12-small.png')" size="30" />
              </template>
              <h6 class="mb-0">Nguyễn Thị Văn Tèo</h6>
              <span class="text-muted">Nhân viên sell</span>
            </b-media>
            <b-collapse id="report-list-with-icon4">
              <b-table responsive :items="items" :fields="fields" class="mb-0">
                <template #cell(Phone)="data">
                  <span class="text-nowrap">
                    {{ data.value }}
                  </span>
                </template>

                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  {{ data.value }}
                </template>
              </b-table>
            </b-collapse>
          </app-timeline-item>
        </app-timeline>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BMedia,
  BButton,
  BCollapse,
  VBToggle,
  VBTooltip,
  BCard,
  BRow,
  BCol,
  BTable,
} from 'bootstrap-vue';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';
import Ripple from 'vue-ripple-directive';
import { avatarText } from '@core/utils/filter';

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BMedia,
    BAvatar,
    BButton,
    BCollapse,
    BCard,
    BRow,
    BCol,
    BTable,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  data() {
    return {
      fields: [
        {
          key: 'id',
          label: 'STT',
        },
        {
          key: 'param',
          label: 'Thông số',
        },
        {
          key: 'oldValue',
          label: 'Giá trị cũ',
        },
        {
          key: 'newValue',
          label: 'Giá trị mới',
        },
      ],
      items: [
        {
          id: '1',
          param: 'Phí',
          oldValue: '500,000',
          newValue: '490,000',
        },
        {
          id: '2',
          param: 'Phí',
          oldValue: '500,000',
          newValue: '490,000',
        },
      ],
    };
  },
  methods: {
    avatarText,
  },
};
</script>
